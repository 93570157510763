import { css } from 'styled-components';
import { font } from '../vars/font.style';
import { clamp } from '../utils/conversion.style';
import { colors } from './colors.style';

const TextStyles = {
  heading1: {
    size: {
      min: 48,
      max: 90,
    },
    lineHeight: 1,
  },
  heading2: {
    size: {
      min: 34,
      max: 56,
    },
    lineHeight: 1,
  },
  heading3: {
    size: {
      min: 26,
      max: 42,
    },
    lineHeight: 1.16,
  },
  heading4: {
    size: {
      min: 22,
      max: 36,
    },
    lineHeight: 1.12,
  },
  heading5: {
    size: {
      min: 18,
      max: 28,
    },
    lineHeight: 1.14,
  },
  heading6: {
    size: {
      min: 22,
      max: 28,
    },
    lineHeight: 1.2,
  },
  heading7: {
    size: {
      min: 18,
      max: 18,
    },
    lineHeight: 1.1,
  },
  bodyLarge: {
    size: {
      min: 18,
      max: 22,
    },
    lineHeight: 1.47,
  },
  body: {
    size: {
      min: 16,
      max: 18,
    },
    lineHeight: 1.3,
  },
  bodySmall: {
    size: {
      min: 15,
      max: 16,
    },
    lineHeight: 1.3,
  },
  bodyXSmall: {
    size: {
      min: 10,
      max: 14,
    },
    lineHeight: 1.3,
  },
  caption: {
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.18,
  },
  cta: {
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.1,
  },
  heroTitle: {
    size: {
      min: 42,
      max: 72,
    },
    lineHeight: 1.1,
  },
};

const Heading1 = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.heading1.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.heading1.size.min,
    TextStyles.heading1.size.max,
  )}
`;

const Heading2 = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.heading2.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.heading2.size.min,
    TextStyles.heading2.size.max,
  )}
`;

const Heading3 = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.heading3.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.heading3.size.min,
    TextStyles.heading3.size.max,
  )}
`;

const Heading4 = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.heading4.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.heading4.size.min,
    TextStyles.heading4.size.max,
  )}
`;

const Heading5 = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.heading5.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.heading5.size.min,
    TextStyles.heading5.size.max,
  )}
`;

const Heading6 = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.medium};
  line-height: ${TextStyles.heading6.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.heading6.size.min,
    TextStyles.heading6.size.max,
  )}
`;

const Heading7 = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.bold};
  line-height: ${TextStyles.heading7.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.heading7.size.min,
    TextStyles.heading7.size.max,
  )}
`;

const TextBodyLarge = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.bodyLarge.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.bodyLarge.size.min,
    TextStyles.bodyLarge.size.max,
  )}
`;

const TextBody = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.body.lineHeight};
  letter-spacing: -0.01em;
  ${clamp('font-size', TextStyles.body.size.min, TextStyles.body.size.max)}
`;

const TextBodySmall = css`
  font-family: ${font.primary};
  ${clamp(
    'font-size',
    TextStyles.bodySmall.size.min,
    TextStyles.bodySmall.size.max,
  )}
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.bodySmall.lineHeight};
  letter-spacing: -0.01em;
`;

const TextBodyXSmall = css`
  font-family: ${font.primary};
  ${clamp(
    'font-size',
    TextStyles.bodyXSmall.size.min,
    TextStyles.bodyXSmall.size.max,
  )}
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.bodyXSmall.lineHeight};
  letter-spacing: -0.01em;
`;

const TextCaption = css`
  font-family: ${font.secondary};
  font-weight: ${font.weight.medium};
  line-height: ${TextStyles.caption.lineHeight};
  letter-spacing: 0.01em;
  ${clamp(
    'font-size',
    TextStyles.caption.size.min,
    TextStyles.caption.size.max,
  )}
`;

const TextCTA = css`
  font-family: ${font.secondary};
  font-weight: ${font.weight.medium};
  line-height: ${TextStyles.cta.lineHeight};
  letter-spacing: 0.01em;
  text-transform: uppercase;
  ${clamp('font-size', TextStyles.cta.size.min, TextStyles.cta.size.max)}
`;

const TextLabel = css`
  font-family: ${font.secondary};
  font-weight: ${font.weight.medium};
  line-height: ${TextStyles.cta.lineHeight};
  letter-spacing: 0.01em;
  margin-right: 0.8rem;
  text-transform: uppercase;
  ${clamp('font-size', TextStyles.cta.size.min, TextStyles.cta.size.max)}

  &:last-child {
    margin-right: 0;
  }

  span > span > span {
    background-color: ${colors.pastelGreen400};
    border-radius: 0.2rem;
    padding: 0.3rem 0.6rem 0.5rem;
  }
`;

const HeroTitle = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.heroTitle.lineHeight};
  letter-spacing: -0.013em;
  ${clamp(
    'font-size',
    TextStyles.heroTitle.size.min,
    TextStyles.heroTitle.size.max,
  )}
`;

export const TextStyle = {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Heading7,
  TextBodyLarge,
  TextBody,
  TextBodySmall,
  TextBodyXSmall,
  TextCaption,
  TextCTA,
  TextLabel,
  HeroTitle,
};
