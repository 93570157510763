import { transparentize } from 'polished';
import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { animation } from '../../styles/vars/animation.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { font } from '../../styles/vars/font.style';
import { zIndex } from '../../styles/vars/layout.style';
import { mq } from '../../styles/vars/media-queries.style';
import particleBackground from './images/mobile-nav-particles.svg';

export const HeaderSkipLink = styled.a`
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.8rem 1.6rem;
  transform: translate(-100%, -100%);

  &:focus {
    transform: translate(0, 0);
  }
`;

export const HeaderMain = styled.header`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  ${clamp('padding-top', 22, 32)}
  ${clamp('padding-left', 22, 50)}
  ${clamp('padding-right', 22, 50)}
  z-index: ${zIndex.header};
  transition: opacity ${animation.pageExitDuration}s ${easings.inOut.quad};

  ${mq.tabletL} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .page-transition-link {
    border-bottom: none;
  }

  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : undefined)};
`;

export const HeaderLogo = styled.h1`
  position: relative;
  ${clamp('width', 120, 136)}
  ${clamp('height', 26.4, 31)}
  color: ${({ dark }) => (dark ? colors.humeBlack700 : colors.light)};
  z-index: 10;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  path {
    transition: fill ${animation.pageExitDuration}s ${easings.inOut.quad};
  }
`;

export const HeaderNav = styled.nav`
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: ${({ dark }) => (dark ? colors.humeBlack700 : colors.light)};
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : undefined)};
  transition: opacity 0.3s ease;

  ${mq.tabletLMax} {
    display: none;
  }
`;

export const NavWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;

  color: ${({ dark }) => (dark ? colors.humeBlack700 : colors.light)};

  display: none;

  ${mq.tabletL} {
    display: block;
  }
`;

export const HeaderMenu = styled.ul`
  display: none;
  list-style: none;

  ${mq.tabletL} {
    display: flex;
    align-items: center;
  }
`;

export const HeaderMenuToggle = styled.button`
  position: absolute;
  ${clamp('top', 24, 30)}
  ${clamp('right', 22, 50)}
  display: flex;
  align-items: center;
  width: 3rem;
  height: 3rem;
  z-index: ${zIndex.menuToggle};
  color: ${({ light }) => (light ? colors.light : colors.humeBlack700)};
  border: none;
  background-color: transparent;
  transition: color 0.2s ${easings.inOut.cubic};

  ${mq.tabletL} {
    display: none;
  }
`;

export const HeaderMenuToggleOpen = styled.span`
  position: absolute;
  top: calc(50% - 0.4rem);
  left: calc(50% - 1.5rem);
  transform: ${({ show }) =>
    show ? 'scale(1) rotate(0deg)' : 'scale(0.5) rotate(-45deg)'};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.2s ease;
`;

export const HeaderMenuToggleClose = styled.span`
  position: absolute;
  top: calc(50% - 1.15rem);
  left: calc(50% - 1.15rem);
  color: ${colors.light};
  transform: ${({ show }) =>
    show ? 'scale(1) rotate(0deg)' : 'scale(0.5) rotate(45deg)'};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.2s ease;
`;

export const HeaderMenuItem = styled.li`
  font-weight: ${font.weight.regular};
  font-size: 1.8rem;
  line-height: 1.3;
  letter-spacing: -0.01em;

  ${mq.deskL} {
    span {
      font-size: 1.8rem;
    }
  }

  & + & {
    margin-left: 5rem;
  }

  a {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: calc(100% + 0.2rem);
      left: 0;
      right: 0;
      display: block;
      height: 0.1rem;
      background-color: currentColor;
      transform-origin: 0% 50%;
      transform: ${({ active }) => (active ? 'scaleX(1)' : 'scaleX(0)')};
      transition: transform 0.15s ${easings.inOut.cubic};
    }
  }
`;

export const HeaderMobileMain = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: calc((var(--vh) * 100));
  left: 0;
  z-index: ${zIndex.header};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  color: ${colors.light};
  background-color: ${colors.humeBlack700};
  background-image: url(${particleBackground});
  background-repeat: no-repeat;
  background-position: right bottom;
  opacity: 0;

  ${mq.tabletL} {
    display: none;
  }
`;

export const HeaderMobileMenu = styled.ul`
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: 10rem 2.2rem 2.8rem;
  list-style: none;
`;

export const HeaderMobileMenuItem = styled.li`
  position: relative;

  a {
    display: block;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &:first-child a {
    padding-top: 0;
  }
`;

export const HeaderMobileText = styled.div`
  opacity: 0;

  a {
    border: none;
  }
`;

export const HeaderMobileMenuBorder = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.1rem;
  background-color: ${transparentize(0.8, colors.light)};
  opacity: 0;
`;

export const HeaderMobileContact = styled.button`
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: ${colors.light};
  border: none;
  background-color: transparent;
`;

export const HeaderMobileLogo = styled.div`
  position: relative;
  width: 2.843rem;
  height: 2.774rem;
  color: ${colors.light};

  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const SlideMask = styled.span`
  position: relative;
  display: block;
  overflow: hidden;
`;

export const SlideMaskChild = styled.span`
  display: block;
  will-change: transform;
  transition: transform 0.5s ${easings.buttonHover.hover};

  :nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(110%);
  }

  ${mq.desk} {
    a:hover &,
    button:hover & {
      &:nth-child(1) {
        transform: translateY(-100%);
      }

      &:nth-child(2) {
        transform: translateY(0);
      }
    }
  }
`;
