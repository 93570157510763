import React, { useReducer, createContext } from 'react';

export const ContextState = createContext();
export const ContextDispatch = createContext();

const initialState = {
  showTransitionMask: true,
  mobileNavIsOpen: false,
  contactIsOpen: true,
  pathname: typeof window === 'undefined' ? null : window.location.pathname,
  previousPathname: '/',
  popstate: 0,
  scrollSection: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        previousPathname: state.pathname,
        pathname: action.payload,
      };
    }

    case 'SCROLL_TO_SECTION': {
      return {
        ...state,
        scrollSection: action.payload,
      };
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      };
    }

    case 'SHOW_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: true,
      };
    }
    case 'HIDE_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: false,
      };
    }

    case 'SHOW_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: true,
      };
    }
    case 'HIDE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: false,
      };
    }
    case 'TOGGLE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: !state.mobileNavIsOpen,
      };
    }

    case 'OPEN_CONTACT': {
      return {
        ...state,
        contactIsOpen: true,
      };
    }

    case 'CLOSE_CONTACT': {
      return {
        ...state,
        contactIsOpen: false,
      };
    }

    default:
      throw new Error('Bad action type');
  }
};

const Context = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ContextState.Provider value={state}>
      <ContextDispatch.Provider value={dispatch}>
        {props.children}
      </ContextDispatch.Provider>
    </ContextState.Provider>
  );
};

export default Context;
