import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import gsap from 'gsap';
import debounce from 'lodash/debounce';
import Routes from '../../routes';
import PageTransitionLink from '../PageTransitionLink';
import { Heading2, Heading5, TextBodyLarge } from '../TextStyles';
import LogoColor from '../_svgs/LogoColor';
import Button from '../Button';
import {
  disablePageScroll,
  enablePageScroll,
  focusElement,
} from '../../utils/utils';
import { ContextDispatch, ContextState } from 'components/Providers';
import { buttonThemes, buttonVariants } from '../Button/index.style';
import Hamburger from '../_svgs/Hamburger';
import HamburgerClose from '../_svgs/HamburgerClose';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';

import {
  HeaderSkipLink,
  HeaderMain,
  HeaderNav,
  NavWrapper,
  HeaderLogo,
  HeaderMenuToggle,
  HeaderMobileMain,
  HeaderMobileMenu,
  HeaderMobileMenuItem,
  HeaderMobileMenuBorder,
  HeaderMobileText,
  HeaderMenuToggleOpen,
  HeaderMenuToggleClose,
} from './index.style';
import { breakpoints } from '../../styles/vars/breakpoints.style';
import NavigationMenu from '../NavigationMenu';

const Header = ({ location }) => {
  const [showHeader, setShowHeader] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const [darkMenu, setDarkMenu] = useState(true);
  const [darkLogo, setDarkLogo] = useState(true);
  const store = useContext(ContextState);
  const storeDispatch = useContext(ContextDispatch);
  const headerRef = useRef();
  const headerMobileHomeRef = useRef();
  const headerMobileRef = useRef();
  const headerMobileTextRefs = useRef([]);
  const headerMobileBorderRefs = useRef([]);
  const contentId = 'scroll-container';
  const pathname = useRef(store.pathname);
  const {
    site: {
      siteMetadata: { signUpURL, loginURL },
    },
    allContentfulNavigation: {
      nodes: [{ links }],
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          signUpURL
          loginURL
        }
      }
      allContentfulNavigation {
        nodes {
          links {
            title
            url
            sublinks {
              title
              subtitle
              url
              scrollSection
            }
          }
        }
      }
    }
  `);
  const skipToContent = (e) => {
    const content = document.getElementById(contentId);

    e.preventDefault();
    focusElement(content);
  };

  const toggleMobileMenu = () => {
    storeDispatch({ type: 'TOGGLE_MOBILE_NAV' });
  };

  const nextPageIsSolutions = () => {
    return (
      pathname.current.indexOf(Routes.solutions) > -1 &&
      pathname.current.length <= Routes.solutions.length + 1
    );
  };

  const nextPageIsContact = () => {
    return (
      pathname.current.indexOf(Routes.contact) > -1 &&
      pathname.current.length <= Routes.contact.length + 1
    );
  };

  const nextPageIsMap = () => {
    return (
      pathname.current.indexOf(Routes.solutions) > -1 &&
      pathname.current.length > Routes.solutions.length + 1
    );
  };

  const nextLogoIsDark = useCallback(() => {
    if (
      nextPageIsSolutions() ||
      nextPageIsContact() ||
      (nextPageIsMap() && window.innerWidth < breakpoints.tabletL)
    ) {
      return false;
    }

    return true;
  }, []);

  const nextMenuIsDark = useCallback(() => {
    if (nextPageIsSolutions() || nextPageIsContact()) {
      return false;
    }

    if (window.innerWidth < breakpoints.tabletL) {
      if (nextPageIsMap()) {
        return false;
      }
    }

    if (nextPageIsSolutions()) {
      if (window.innerWidth < breakpoints.desk) {
        return false;
      }
    }

    return true;
  }, []);

  const nextHeaderIsHidden = useCallback(() => {
    return nextPageIsMap() && window.innerWidth >= breakpoints.tabletL;
  }, []);

  const updateHeaderElements = useCallback(() => {
    setDarkLogo(nextLogoIsDark());
    setDarkMenu(nextMenuIsDark());
    setShowHeader(!nextHeaderIsHidden());
  }, [nextLogoIsDark, nextMenuIsDark, nextHeaderIsHidden]);

  useEffect(() => {
    pathname.current = store.pathname;
  }, [store.pathname]);

  useEffect(() => {
    updateHeaderElements();
  }, [store.popstate, updateHeaderElements]);

  useEffect(() => {
    const hideMask = !store.showTransitionMask;

    if (hideMask) {
      setDarkLogo(nextLogoIsDark());
      setDarkMenu(nextMenuIsDark());
      setShowHeader(!nextHeaderIsHidden());
    }
  }, [
    store.showTransitionMask,
    nextLogoIsDark,
    nextMenuIsDark,
    nextHeaderIsHidden,
  ]);

  useEffect(() => {
    if (store.mobileNavIsOpen) {
      const open = gsap.timeline({
        onStart: () => {
          setDisableToggle(true);
          disablePageScroll();
        },
        onComplete: () => {
          setDisableToggle(false);
        },
      });

      open
        .set(headerMobileRef.current, {
          opacity: 1,
        })
        .fromTo(
          headerMobileRef.current,
          {
            xPercent: 100,
          },
          {
            xPercent: 0,
            duration: 0.3,
            ease: 'power3.inOut',
          },
        )
        .fromTo(
          headerMobileTextRefs.current,
          {
            x: 30,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.9,
            ease: 'power3.out',
            stagger: 0.08,
          },
          0.2,
        )
        .fromTo(
          headerMobileBorderRefs.current,
          {
            x: 40,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.5,
            ease: 'power3.out',
            stagger: 0.08,
          },
          0.3,
        )
        .fromTo(
          headerMobileHomeRef.current,
          {
            y: 20,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.7,
            ease: 'power3.out',
          },
          0.3,
        );
    } else {
      const close = gsap.timeline({
        onStart: () => {
          setDisableToggle(true);
          enablePageScroll();
        },
        onComplete: () => {
          setDisableToggle(false);
        },
      });

      close
        .to(headerMobileRef.current, {
          xPercent: 100,
          duration: 0.2,
          ease: 'power3.inOut',
        })
        .to(
          [...headerMobileTextRefs.current, ...headerMobileBorderRefs.current],
          {
            x: 100,
            opacity: 0,
            duration: 0.2,
            ease: 'power3.inOut',
          },
          0,
        )
        .to(headerMobileHomeRef.current, {
          y: 20,
          opacity: 0,
          duration: 0.2,
          ease: 'power3.inOut',
        });
    }
  }, [store.mobileNavIsOpen]);

  useEffect(() => {
    window.addEventListener('resize', debounce(updateHeaderElements, 400));

    return () => {
      window.removeEventListener('resize', updateHeaderElements);
    };
  }, [updateHeaderElements]);

  const MobileSublinks = ({ sublinks }) => {
    return (
      <>
        {sublinks.map(({ title, url, scrollSection = null }) => (
          <PageTransitionLink
            key={title}
            to={url}
            scrollSection={scrollSection}
          >
            <Heading5>{title}</Heading5>
          </PageTransitionLink>
        ))}
      </>
    );
  };

  return (
    <>
      <HeaderMain ref={headerRef} hide={!showHeader} id="header">
        <HeaderSkipLink href={`#${contentId}`} onClick={skipToContent}>
          <TextBodyLarge>Skip to content</TextBodyLarge>
        </HeaderSkipLink>

        <PageTransitionLink to={Routes.home}>
          <HeaderLogo dark={darkLogo}>
            <LogoColor />
          </HeaderLogo>
        </PageTransitionLink>

        <NavWrapper dark={darkMenu}>
          <NavigationMenu links={links} dark={darkMenu} location={location} />
        </NavWrapper>

        <HeaderNav hide={!showHeader} dark={darkMenu}>
          <Button
            iconRight={<IconArrowheadRight />}
            as="a"
            href={signUpURL}
            variant={buttonVariants.outline}
            theme={darkMenu ? buttonThemes.dark : buttonThemes.light}
            size="small"
            newTab
            onClick={() => {
              window.gtag('event', 'conversion', {
                send_to: 'AW-10927571180/jwKuCO2k6MMDEOyB1too',
              });
            }}
          >
            Sign Up
          </Button>
        </HeaderNav>
      </HeaderMain>

      <HeaderMenuToggle
        light={store.mobileNavIsOpen || !darkLogo}
        onClick={toggleMobileMenu}
        disabled={disableToggle}
      >
        <HeaderMenuToggleOpen show={!store.mobileNavIsOpen}>
          <Hamburger />
        </HeaderMenuToggleOpen>

        <HeaderMenuToggleClose show={store.mobileNavIsOpen}>
          <HamburgerClose />
        </HeaderMenuToggleClose>
      </HeaderMenuToggle>

      <HeaderMobileMain ref={headerMobileRef} open={store.mobileNavIsOpen}>
        <HeaderMobileMenu>
          {links.map(({ title, url, sublinks }, index) => (
            <HeaderMobileMenuItem
              key={title}
              active={
                !store.pathname
                  ? location.pathname.indexOf(url) > -1
                  : store.pathname.indexOf(url) > -1
              }
            >
              <HeaderMobileText
                ref={(ref) => (headerMobileTextRefs.current[index] = ref)}
              >
                <PageTransitionLink to={url}>
                  <Heading2>{title}</Heading2>
                </PageTransitionLink>

                {sublinks && <MobileSublinks sublinks={sublinks} />}
              </HeaderMobileText>
              <HeaderMobileMenuBorder
                ref={(ref) => (headerMobileBorderRefs.current[index] = ref)}
              />
            </HeaderMobileMenuItem>
          ))}

          {/* <HeaderMobileMenuItem
            onClick={() => {
              window.gtag('event', 'login', {
                send_to: 'AW-10927571180/jwKuCO2k6MMDEOyB1too',
              })
            }}
          >
            <HeaderMobileText
              ref={ref => (headerMobileTextRefs.current[5] = ref)}
            >
              <a href={loginURL} target="_blank" rel="noreferrer">
                <Heading2>Login</Heading2>
              </a>
            </HeaderMobileText>
            <HeaderMobileMenuBorder
              ref={ref => (headerMobileBorderRefs.current[5] = ref)}
            />
          </HeaderMobileMenuItem> */}

          <HeaderMobileMenuItem
            onClick={() => {
              window.gtag('event', 'conversion', {
                send_to: 'AW-10927571180/jwKuCO2k6MMDEOyB1too',
              });
            }}
          >
            <HeaderMobileText
              ref={(ref) => (headerMobileTextRefs.current[6] = ref)}
            >
              <a href={signUpURL} target="_blank" rel="noreferrer">
                <Heading2>Sign Up</Heading2>
              </a>
            </HeaderMobileText>
          </HeaderMobileMenuItem>
        </HeaderMobileMenu>
      </HeaderMobileMain>
    </>
  );
};

export default Header;
