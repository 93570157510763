export const zIndex = {
  pageTransitionMask: 240,
  header: 3,
  drawer: 4,
  solution: 5,
  menuToggle: 6,
  contact: 7,
  cookieBar: 8,
};

// 6,12,18,24,30,48,60,72,120,180,240
