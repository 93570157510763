import React from 'react';
import {
  ButtonWrapper,
  ButtonMain,
  ButtonText,
  ButtonIconLeft,
  ButtonIconRight,
  ButtonIconOnly,
  ButtonLoading,
  buttonVariants,
  buttonThemes,
  SlideMask,
  SlideMaskFill,
  SlideMaskChild,
  SlideMaskChildFill,
  ButtonIconWrapper,
  ButtonIconItem,
  ButtonIconItemNoHover,
} from './index.style';

const Button = ({
  type = 'button',
  as = 'button',
  size = 'medium',
  theme = buttonThemes.dark,
  variant = buttonVariants.fill,
  disabled = false,
  inactive = false,
  fullWidth = false,
  loading = false,
  ariaLabel = undefined,
  iconLeft,
  iconRight,
  iconOnly,
  onClick,
  children,
  lower = false,
  href,
  newTab,
  arrowOut,
}) => (
  <ButtonWrapper
    type={type}
    as={as}
    variant={variant}
    theme={theme}
    size={size}
    disabled={disabled}
    inactive={inactive}
    fullWidth={fullWidth}
    aria-label={ariaLabel}
    onClick={onClick}
    lower={lower}
    href={href}
    left={iconLeft ? true : false}
    arrowOut={arrowOut ? true : false}
    target={newTab ? `_blank` : ``}
    rel={newTab ? `noreferrer` : ``}
  >
    <ButtonLoading isLoading={loading}>
      <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false">
        <circle
          cx="16"
          cy="16"
          r="14.5"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </svg>
    </ButtonLoading>
    <ButtonMain
      isLoading={loading}
      var={variant}
      icon={!iconLeft && !iconRight && !iconOnly && 'false'}
    >
      {variant === buttonVariants.plain || variant === buttonVariants.text ? (
        !iconRight && !iconLeft && !iconOnly ? (
          //plain button variant with no icons
          <SlideMask>
            <SlideMaskChild>
              {children && (
                <ButtonText
                  lower={variant === buttonVariants.text ? `true` : lower}
                >
                  {children}
                </ButtonText>
              )}
            </SlideMaskChild>
            <SlideMaskChild aria-hidden>
              {children && (
                <ButtonText
                  lower={variant === buttonVariants.text ? `true` : lower}
                >
                  {children}
                </ButtonText>
              )}
            </SlideMaskChild>
          </SlideMask>
        ) : (
          //plain button variant with icon
          <>
            {iconLeft && (
              <ButtonIconWrapper left={true} variant={variant}>
                <ButtonIconItem left={true}>
                  <ButtonIconLeft left={true}>{iconLeft}</ButtonIconLeft>
                </ButtonIconItem>
                <ButtonIconItem left={true}>
                  <ButtonIconLeft left={true}>{iconLeft}</ButtonIconLeft>
                </ButtonIconItem>
              </ButtonIconWrapper>
            )}
            {children && (
              <ButtonText
                lower={variant === buttonVariants.text ? `true` : lower}
              >
                {children}
              </ButtonText>
            )}
            {iconRight && (
              <ButtonIconWrapper arrowOut={arrowOut} variant={variant}>
                <ButtonIconItem arrowOut={arrowOut}>
                  <ButtonIconRight>{iconRight}</ButtonIconRight>
                </ButtonIconItem>
                <ButtonIconItem arrowOut={arrowOut}>
                  <ButtonIconRight>{iconRight}</ButtonIconRight>
                </ButtonIconItem>
              </ButtonIconWrapper>
            )}
            {iconOnly && (
              <ButtonIconWrapper iconOnly={true} variant={variant}>
                <ButtonIconItem>
                  <ButtonIconOnly>{iconOnly}</ButtonIconOnly>
                </ButtonIconItem>
                <ButtonIconItem>
                  <ButtonIconOnly>{iconOnly}</ButtonIconOnly>
                </ButtonIconItem>
              </ButtonIconWrapper>
            )}
          </>
        )
      ) : !iconRight && !iconLeft ? (
        // --mask that animates
        <SlideMaskFill>
          <SlideMaskChildFill>
            {iconLeft && (
              <>
                <ButtonIconItemNoHover left={true}>
                  <ButtonIconLeft>{iconLeft}</ButtonIconLeft>
                </ButtonIconItemNoHover>
              </>
            )}
            {children && <ButtonText>{children}</ButtonText>}
            {iconRight && (
              <>
                <ButtonIconItemNoHover>
                  <ButtonIconRight>{iconRight}</ButtonIconRight>
                </ButtonIconItemNoHover>
              </>
            )}
          </SlideMaskChildFill>
          <SlideMaskChildFill>
            {iconLeft && (
              <>
                <ButtonIconItemNoHover left={true}>
                  <ButtonIconLeft>{iconLeft}</ButtonIconLeft>
                </ButtonIconItemNoHover>
              </>
            )}
            {children && <ButtonText>{children}</ButtonText>}
            {iconRight && (
              <>
                <ButtonIconItemNoHover>
                  <ButtonIconRight>{iconRight}</ButtonIconRight>
                </ButtonIconItemNoHover>
              </>
            )}
          </SlideMaskChildFill>
        </SlideMaskFill>
      ) : (
        // --arrow that animates
        <>
          {iconLeft && (
            <ButtonIconWrapper left={true}>
              <ButtonIconItem left={true}>
                <ButtonIconLeft left={true}>{iconLeft}</ButtonIconLeft>
              </ButtonIconItem>
              <ButtonIconItem left={true}>
                <ButtonIconLeft left={true}>{iconLeft}</ButtonIconLeft>
              </ButtonIconItem>
            </ButtonIconWrapper>
          )}
          {children && <ButtonText>{children}</ButtonText>}
          {iconRight && (
            <ButtonIconWrapper>
              <ButtonIconItem>
                <ButtonIconRight>{iconRight}</ButtonIconRight>
              </ButtonIconItem>
              <ButtonIconItem>
                <ButtonIconRight>{iconRight}</ButtonIconRight>
              </ButtonIconItem>
            </ButtonIconWrapper>
          )}
        </>
      )}
    </ButtonMain>
  </ButtonWrapper>
);

export default Button;
