export const colors = {
  black2: '#232323',
  light: '#FFF4E8',
  lavenderMagenta: '#eb93f3',
  lavender: '#BF94E4',
  lightLime: '#CAE797',
  pastelGreen: '#7CD28A',
  salmonCream: '#E8CECE',
  lightOrange: '#F7CD7A',
  rajah: '#ffb760',
  tulip: '#ff8d87',
  roses: '#E0787B',
  mediumSkyBlue: '#79E5EC',
  lightBlue: '#9CDBFB',
  aero: '#7EB7EF',
  blue: '#3846E9',

  neutralBlack: '#000000',
  neutralWhite: '#FFFFFF',

  humeBlack800: '#1A1A1A',
  humeBlack700: '#353535',
  humeBlack600: '#404040',
  humeBlack500: '#4D4D4D',
  humeBlack400: '#666666',
  humeBlack300: '#808080',
  humeBlack200: '#999999',
  humeBlack100: '#BFBFBF',

  humeTan700: '#999087',
  humeTan600: '#BFB5AA',
  humeTan500: '#E5DACE',
  humeTan400: '#FFF4E8',
  humeTan300: '#FFF9F2',
  humeTan200: '#FFFDFA',

  pastelGreen700: '#677367',
  pastelGreen600: '#808C80',
  pastelGreen500: '#A4B2A4',
  pastelGreen400: '#C6D3C5',
  pastelGreen300: '#D8E5D8',
  pastelGreen200: '#E6F2E6',
  pastelGreen100: '#F3FCF2',

  pastelBlue700: '#707C80',
  pastelBlue600: '#889599',
  pastelBlue500: '#ACBBBF',
  pastelBlue400: '#CDDDE2',
  pastelBlue300: '#DCEDF2',
  pastelBlue200: '#E8F6FA',
  pastelBlue100: '#F5FBFC',

  pastelPink700: '#998484',
  pastelPink600: '#B29B9B',
  pastelPink500: '#CCB4B4',
  pastelPink400: '#E8CECE',
  pastelPink300: '#F0DADA',
  pastelPink200: '#F5E6E6',
  pastelPink100: '#FCF1F1',

  pastelOrange300: '#FFE1C2',
  pastelOrange400: '#FED4AA',

  accentOrange: '#FFC783',
  accentPink: '#FBABA7',
  accentPurple: '#EEC3F2',
  accentBlue: '#B7D2EC',
  accentTurquoise: '#B7E9EC',
};
