import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';
import { usePageTransition } from '../../hooks/usePageTransition';
import { useContext } from 'react';
import { ContextDispatch, ContextState } from 'components/Providers';

const PageTransitionLink = ({ children, to, fill, scrollSection }) => {
  const pageTransition = usePageTransition();
  const store = useContext(ContextState);
  const storeDispatch = useContext(ContextDispatch);

  return (
    <TransitionLink
      to={to}
      exit={pageTransition.exit}
      entry={pageTransition.entry}
      onClick={() => {
        storeDispatch({ type: 'HIDE_MOBILE_NAV' });
        if (
          to.indexOf(store.pathname) === -1 ||
          to.length !== store.pathname.length
        ) {
          storeDispatch({ type: 'UPDATE_PATHNAME', payload: to });
          storeDispatch({ type: 'SHOW_TRANSITION_MASK' });

          if (store.contactIsOpen) {
            storeDispatch({ type: 'CLOSE_CONTACT' });
          }
        }
        if (scrollSection) {
          storeDispatch({ type: 'SCROLL_TO_SECTION', payload: scrollSection });
        } else {
          storeDispatch({ type: 'SCROLL_TO_SECTION', payload: null });
        }
      }}
      className={`page-transition-link ${
        fill ? 'page-transition-link--fill' : ''
      }`}
    >
      {children}
    </TransitionLink>
  );
};

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  to: PropTypes.string,
};

PageTransitionLink.defaultProps = {};

export default PageTransitionLink;
