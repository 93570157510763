import React, { createContext, useEffect, useState } from 'react';
import { breakpoints } from 'styles/vars/breakpoints.style';

export const ScreenContext = createContext();

const Screen = ({ children }) => {
  const [screen, setScreen] = useState({
    tiny: false,
    mobile: false,
    mobileL: false,
    tabletP: false,
    tabletL: false,
    desk: false,
    deskL: false,
    contained: false,
  });

  useEffect(() => {
    const determineScreen = () => {
      setScreen({
        tiny: window.innerWidth < breakpoints.mobile,
        mobile:
          window.innerWidth >= breakpoints.mobile &&
          window.innerWidth < breakpoints.mobileL,
        mobileL:
          window.innerWidth >= breakpoints.mobileL &&
          window.innerWidth < breakpoints.tabletP,
        tabletP:
          window.innerWidth >= breakpoints.tabletP &&
          window.innerWidth < breakpoints.tabletL,
        tabletL:
          window.innerWidth >= breakpoints.tabletL &&
          window.innerWidth < breakpoints.desk,
        desk:
          window.innerWidth >= breakpoints.desk &&
          window.innerWidth < breakpoints.deskL,
        deskL:
          window.innerWidth >= breakpoints.deskL &&
          window.innerWidth < breakpoints.contained,
        contained: window.innerWidth >= breakpoints.contained,
      });
    };

    determineScreen();
    window.addEventListener('resize', determineScreen);

    return () => {
      window.removeEventListener('resize', determineScreen);
    };
  }, [setScreen]);

  return (
    <ScreenContext.Provider value={screen}>{children}</ScreenContext.Provider>
  );
};

export default Screen;
