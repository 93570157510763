import { css } from 'styled-components';

export const gatsbyTransitionLinkStyles = css`
  .tl-edges {
    overflow: visible;
  }

  .tl-wrapper {
    float: none;
  }

  .page-transition-link {
    display: inline-flex;
    border: none;

    &--fill {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        height: auto;
      }
    }
  }
`;
