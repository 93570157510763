// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ethics-js": () => import("./../../../src/pages/ethics.js" /* webpackChunkName: "component---src-pages-ethics-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */)
}

