import { css } from 'styled-components';

export const reset = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  svg {
    display: block;
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;
