import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

const ScrollSection = ({
  threshold = 0.2,
  triggerOnce = true,
  header,
  children,
  id = '',
  ...props
}) => (
  <div
    data-scroll-section
    id={id}
    className={header ? `headerScrollSection` : ``}
    {...props}
  >
    <InView triggerOnce={triggerOnce} threshold={threshold}>
      {({ inView, ref }) => (
        <div ref={ref} data-inview={inView.toString()}>
          {React.cloneElement(children, { inView: inView })}
        </div>
      )}
    </InView>
  </div>
);

ScrollSection.propTypes = {
  threshold: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default ScrollSection;
