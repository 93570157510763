import React from 'react';

import Screen, { ScreenContext } from './Screen';
import Context, { ContextState, ContextDispatch } from './Context';

const Providers = ({ children }) => {
  return (
    <Context>
      <Screen>{children}</Screen>
    </Context>
  );
};

export default Providers;

export { ScreenContext, ContextState, ContextDispatch };
