import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Components
import { ContextDispatch } from 'components/Providers';
import TransitionMask from '../PageTransitionMask';
import Gridlines from '../Gridlines';
import Header from '../Header';
import Scroll from '../Scroll';

// Fonts
import FellixRegularEOT from '../../fonts/Fellix/Fellix-Regular.eot';
import FellixRegularWOFF2 from '../../fonts/Fellix/Fellix-Regular.woff2';
import FellixRegularWOFF from '../../fonts/Fellix/Fellix-Regular.woff';
import FellixMediumEOT from '../../fonts/Fellix/Fellix-Medium.eot';
import FellixMediumWOFF2 from '../../fonts/Fellix/Fellix-Medium.woff2';
import FellixMediumWOFF from '../../fonts/Fellix/Fellix-Medium.woff';
import FellixBoldEOT from '../../fonts/Fellix/Fellix-Bold.eot';
import FellixBoldWOFF2 from '../../fonts/Fellix/Fellix-Bold.woff2';
import FellixBoldWOFF from '../../fonts/Fellix/Fellix-Bold.woff';
import FraktionMonoEOT from '../../fonts/FraktionMono/FraktionMono-Medium.eot';
import FraktionMonoWOFF2 from '../../fonts/FraktionMono/FraktionMono-Medium.woff2';
import FraktionMonoWOFF from '../../fonts/FraktionMono/FraktionMono-Medium.woff';

// Styles
import { GlobalStyles } from '../../styles/GlobalStyles.style';
import { animation } from '../../styles/vars/animation.style';
import { font } from '../../styles/vars/font.style';
import ScrollSection from '../ScrollSection';
import CookieBar from '../CookieBar';

const Layout = ({ children, location }) => {
  const [keyMode, setKeyMode] = useState(false);
  const storeDispatch = useContext(ContextDispatch);

  const setMouseMode = () => {
    if (!keyMode) return;
    setKeyMode(false);
    document.body.classList.remove('keyboard-in-use');
  };

  const setKeyboardMode = () => {
    if (keyMode) return;
    setKeyMode(true);
    document.body.classList.add('keyboard-in-use');
  };

  const handleBrowserNavigationInteraction = useCallback(
    (e) => {
      storeDispatch({
        type: 'UPDATE_PATHNAME',
        payload: window.location.pathname,
      });
      storeDispatch({ type: 'UPDATE_POP_STATE' });
    },
    [storeDispatch],
  );

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBrowserNavigationInteraction);

    return () => {
      window.removeEventListener(
        'popstate',
        handleBrowserNavigationInteraction,
      );
    };
  }, [handleBrowserNavigationInteraction]);

  useEffect(() => {
    setViewportHeight();

    document.body.addEventListener('mousedown', setMouseMode);
    window.addEventListener('resize', setViewportHeight);
    document.body.addEventListener('keydown', setKeyboardMode);

    return () => {
      document.body.removeEventListener('mousedown', setMouseMode);
      window.removeEventListener('resize', setViewportHeight);
      document.body.removeEventListener('keydown', setKeyboardMode);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      storeDispatch({ type: 'HIDE_TRANSITION_MASK' });
    }, animation.siteLoadDelay * 1000);
  }, [storeDispatch]);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <style>{`
          @font-face {
            font-family: 'Fellix';
            src: url(${FellixRegularEOT}) format('eot'),
            url(${FellixRegularWOFF2}) format('woff2'),
              url(${FellixRegularWOFF}) format('woff');
            font-weight: ${font.weight.regular};
          }

          @font-face {
            font-family: 'Fellix';
            src: url(${FellixMediumEOT}) format('eot'),
            url(${FellixMediumWOFF2}) format('woff2'),
              url(${FellixMediumWOFF}) format('woff');
            font-weight: ${font.weight.medium};
          }

          @font-face {
            font-family: 'Fellix';
            src: url(${FellixBoldEOT}) format('eot'),
            url(${FellixBoldWOFF2}) format('woff2'),
              url(${FellixBoldWOFF}) format('woff');
            font-weight: ${font.weight.bold};
          }

          @font-face {
            font-family: 'FraktionMono';
            src: url(${FraktionMonoEOT}) format('eot'),
            url(${FraktionMonoWOFF2}) format('woff2'),
              url(${FraktionMonoWOFF}) format('woff');
            font-weight: ${font.weight.medium};
          }
        `}</style>

        <body className="body" />
      </Helmet>

      <GlobalStyles />

      <Gridlines show={false} />

      <Scroll callbacks={location} />

      <div id="scroll-container" data-scroll-container>
        <ScrollSection header={true}>
          <Header location={location} />
        </ScrollSection>
        {children}
      </div>

      <CookieBar />

      <TransitionMask />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
