import { breakpoints } from 'styles/vars/breakpoints.style';
import gsap from 'gsap';

export function disablePageScroll() {
  window.scroll.stop();
}

export function enablePageScroll() {
  window.scroll.start();
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0);
  el.focus();
}

export function random() {
  return (
    (Math.random() +
      Math.random() +
      Math.random() +
      Math.random() +
      Math.random() +
      Math.random() -
      3) /
    3
  );
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const isBrowser = () => {
  return typeof window !== 'undefined';
};

export const vwToPx = (vw) => {
  if (isBrowser()) {
    const px = vw * (window.innerWidth / 100);
    return px;
  }
};

export function getMedia(
  contained,
  deskL,
  desk,
  tabletL,
  tabletP,
  mobileL,
  mobile,
  tiny,
) {
  if (typeof window !== 'undefined') {
    if (window.innerWidth >= breakpoints.contained) {
      return contained;
    }
    if (window.innerWidth >= breakpoints.deskL) {
      return deskL;
    }
    if (window.innerWidth >= breakpoints.desk) {
      return desk;
    }
    if (window.innerWidth >= breakpoints.tabletL) {
      return tabletL;
    }
    if (window.innerWidth >= breakpoints.tabletP) {
      return tabletP;
    }
    if (window.innerWidth >= breakpoints.mobileL) {
      return mobileL;
    }
    if (window.innerWidth >= breakpoints.mobile) {
      return mobile;
    } else {
      return tiny;
    }
  }

  return contained;
}

export const scrollToSection = (section) => {
  if (window.scroll.scrollTo) {
    window.scroll.scrollTo(section);
  } else {
    gsap.to(window, {
      scrollTo: section.offsetTop ? section.offsetTop + 1 : section,
      duration: 1,
    });
  }
};
