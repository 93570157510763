import React, { useState } from 'react';
import { CookieBarBtn, CookieBarMain } from './index.style';
import { TextBodySmall, TextCTA } from '../TextStyles';
import { useEffect } from 'react';
import PageTransitionLink from '../PageTransitionLink';
import Routes from '../../routes';

const CookieBar = () => {
  const cookieKey = 'gatsby-gdpr-google-analytics';
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookie = document.cookie;

    if (cookie.length === 0) {
      setShow(true);
    } else {
      let accepted = cookie
        .split('; ')
        .find((row) => row.startsWith(`${cookieKey}=`));

      if (accepted) {
        accepted = accepted.split('=')[1];
      }

      if (accepted === 'true') {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  }, []);

  const enableCookies = () => {
    document.cookie = `${cookieKey}=true;path=/`;
    setShow(false);
  };

  return (
    <CookieBarMain show={show}>
      <TextBodySmall>
        By using this website, you agree to{' '}
        <PageTransitionLink to={Routes.privacyPolicy}>
          our use of cookies
        </PageTransitionLink>
        .
        <CookieBarBtn onClick={enableCookies}>
          <TextCTA as="span">OK, got it</TextCTA>
        </CookieBarBtn>
      </TextBodySmall>
    </CookieBarMain>
  );
};

export default CookieBar;
