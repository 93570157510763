import { createGlobalStyle } from 'styled-components';
import { reset } from './partials/reset.style';
import { typography } from './partials/typography.style';
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style';
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style';
import { colors } from './vars/colors.style';
import { zIndex } from './vars/layout.style';
import { mq } from './vars/media-queries.style';

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}

  body {
    background-color: ${colors.light};
    overflow-x: hidden;
    overscroll-behavior: none;

    &.disable-scroll {
      overflow: hidden;
      overscroll-behavior: none;
      touch-action: none;
      height: calc((var(--vh) * 100) - 1px);

      #scroll-container {
        overflow: hidden;
        height: calc((var(--vh) * 100));

        ${mq.tabletL} {
          height: auto;
          overflow: visible;
        }
      }

      ${mq.tabletL} {
        height: auto;
      }
    }
  }

  #portal {
    position: relative;
    z-index: ${zIndex.drawer};
  }

  .dg.ac {
    z-index: 100 !important;
  }

  .headerScrollSection {
    position: relative;
    z-index: ${zIndex.header};
  }

`;
