import React from 'react';

const IconArrowheadRight = ({
  width = 31,
  height = 8,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 8"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M30.354 4.354a.5.5 0 000-.708L27.172.464a.5.5 0 10-.707.708L29.293 4l-2.828 2.828a.5.5 0 10.707.708l3.182-3.182zM0 4.5h30v-1H0v1z"
      />
    </svg>
  );
};

export default IconArrowheadRight;
