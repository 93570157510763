import React from 'react';
import styled from 'styled-components';
import PageTransitionLink from '../PageTransitionLink';
import { Dropdown, DropdownMenuItem } from '../NavDropdown';
import { font } from 'styles/vars/font.style';
import { TextStyle } from 'styles/vars/textStyles.style';
import { easings } from 'styles/vars/easings.style';
import AnimateSlideMask from '../_animation/AnimateSlideMask';

/* TODO:  break this file into reusable components */
const NavigationMenu = ({ links, dark, location }) => {
  return (
    <NavigationWrapper dark={dark}>
      <NavMenuList>
        {links.map(({ title, sublinks, url }) => (
          <NavMenuItem
            key={title}
            active={
              location.pathname.replace(/\//g, '') === url.replace(/\//g, '')
            }
          >
            {!sublinks ? (
              <NavLink href={url}>{title}</NavLink>
            ) : (
              <Dropdown title={title} dark={dark}>
                {sublinks.map(
                  ({ title, subtitle, url, scrollSection = null }, index) => (
                    <DropdownMenuItem
                      key={title}
                      title={title}
                      subtitle={subtitle}
                      href={url}
                      scrollSection={scrollSection}
                      isFeatured={index === 0}
                    />
                  ),
                )}
              </Dropdown>
            )}
          </NavMenuItem>
        ))}
      </NavMenuList>
    </NavigationWrapper>
  );
};

const NavMenuItem = ({ children, active }) => {
  return <NavMenuItemWrapper active={active}>{children}</NavMenuItemWrapper>;
};

const NavLink = ({ href, children }) => {
  return (
    <NavItemTitle>
      <PageTransitionLink to={href}>
        <AnimateSlideMask>{children}</AnimateSlideMask>
      </PageTransitionLink>
    </NavItemTitle>
  );
};

export const NavigationWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-items: center;
`;

export const NavMenuList = styled.ul`
  display: flex;
  gap: 3rem;
  list-style-type: none;
  margin: auto;
`;

export const NavMenuItemWrapper = styled.div`
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: ${({ active }) => (active ? 'none' : 'all')};

  &:before {
    content: '';
    position: absolute;
    top: calc(100% + 0.2rem);
    left: 0;
    right: 0;
    display: block;
    height: 0.1rem;
    background-color: currentColor;
    transform-origin: 0% 50%;
    transform: ${({ active }) => (active ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 0.15s ${easings.inOut.cubic};
  }
`;

export const NavItemTitle = styled.span`
  text-transform: uppercase;
  font-family: ${font.secondary};
  font-size: ${TextStyle.TextBodySmall};
`;

export default NavigationMenu;
