import React, { useRef, useEffect, useContext } from 'react';
import gsap from 'gsap';
import { ContextState } from 'components/Providers';
import LogoDots from '../_svgs/LogoDots';

import {
  TransitionMaskWrapper,
  TransitionMaskInner,
  TransitionLogo,
} from './index.style';
import { animation } from '../../styles/vars/animation.style';

const TransitionMask = () => {
  const { showTransitionMask } = useContext(ContextState);
  const maskRef = useRef(),
    maskInnerRef = useRef(),
    logoRef = useRef(),
    logoSpin = useRef();

  useEffect(() => {
    const showMask = () => {
      maskRef.current.style.display = 'block';

      logoSpin.current = gsap.to(logoRef.current, {
        rotationZ: 3600,
        delay: 0,
        duration: 20,
        ease: 'power1.out',
      });

      gsap.to(logoRef.current, {
        opacity: 1,
        delay: 0.2,
        duration: 0.5,
        ease: 'power3.out',
      });

      gsap.to(maskInnerRef.current, {
        y: '0%',
        duration: animation.pageExitDuration - 0.05,
        ease: 'power3.out',
      });
    };

    const hideMask = () => {
      gsap.to(logoRef.current, {
        opacity: 0,
        delay: 0.2,
        duration: 0.5,
        ease: 'power3.out',
      });

      gsap.to(maskInnerRef.current, {
        y: '-100%',
        delay: 0.5,
        duration: animation.pageEntryDuration,
        ease: 'power3.out',
        onComplete: () => {
          gsap.set(maskInnerRef.current, {
            y: '100%',
          });
          maskRef.current.style.display = 'none';
          if (logoSpin.current) {
            logoSpin.current.kill();
          }
          gsap.set(logoRef.current, {
            rotationZ: 0,
          });
        },
      });
    };

    if (showTransitionMask) {
      showMask();
    } else {
      hideMask();
    }
  }, [showTransitionMask]);

  return (
    <TransitionMaskWrapper ref={maskRef}>
      <TransitionMaskInner ref={maskInnerRef} />
      <TransitionLogo ref={logoRef}>
        <LogoDots />
      </TransitionLogo>
    </TransitionMaskWrapper>
  );
};

export default TransitionMask;
