import React from 'react';
import { SlideMask, SlideMaskChild } from './index.style';
import PropTypes from 'prop-types';

const AnimateSlideMask = ({ children }) => {
  return (
    <SlideMask>
      <SlideMaskChild>{children}</SlideMaskChild>
      <SlideMaskChild>{children}</SlideMaskChild>
    </SlideMask>
  );
};

AnimateSlideMask.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default AnimateSlideMask;
