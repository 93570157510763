import React from 'react';
import PropTypes from 'prop-types';
import { GridlinesColumn, GridlinesMain } from './index.style';
import Grid from '../_layouts/Grid';
import { grid } from '../../styles/vars/grid.style';
import GridItem from '../_layouts/GridItem';
import Container from '../_layouts/Container';

const Gridlines = ({ show }) => {
  const gridColumns = [];

  for (let i = 0; i < grid.colCount; i++) {
    gridColumns.push(
      <GridItem tiny={1}>
        <GridlinesColumn />
      </GridItem>,
    );
  }

  return (
    <GridlinesMain show={show}>
      <Container>
        <Grid data-grid>
          {React.Children.toArray(gridColumns.map((column) => column))}
        </Grid>
      </Container>
    </GridlinesMain>
  );
};

Gridlines.propTypes = {
  show: PropTypes.bool,
};

Gridlines.defaultProps = {
  show: false,
};

export default Gridlines;
