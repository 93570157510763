import styled from 'styled-components';
import { colors } from '../../styles/vars/colors.style';
import { zIndex } from '../../styles/vars/layout.style';

export const TransitionMaskWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.pageTransitionMask};
`;

export const TransitionMaskInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: ${colors.pastelGreen400};
  transform: translateY(0%);
`;

export const TransitionLogo = styled.div`
  margin-left: -1.5rem;
  margin-top: -1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  height: 3.1rem;
  width: 3.1rem;

  svg {
    height: 100%;
    width: 100%;
  }
`;
