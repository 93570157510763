import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import PageTransitionLink from '../PageTransitionLink';
import IconCarat from '../_svgs/IconCarat';
import { NavItemTitle } from '../NavigationMenu';
import { colors } from 'styles/vars/colors.style';
import { TextStyle } from 'styles/vars/textStyles.style';

const DropdownMenuItem = ({
  title,
  subtitle,
  href,
  scrollSection,
  isFeatured = false,
}) => {
  if (isFeatured) {
    return (
      <DropdownFeatured
        tabIndex={-1}
        className="dropdown-menu-item"
        role="menuitem"
      >
        <PageTransitionLink to={href}>
          <DropdownItemContent>
            <DropdownItemTitle>{title}</DropdownItemTitle>
            <DropdownItemSubtitle>{subtitle}</DropdownItemSubtitle>
          </DropdownItemContent>
        </PageTransitionLink>
      </DropdownFeatured>
    );
  }
  return (
    <DropdownItem tabIndex={-1} className="dropdown-menu-item" role="menuitem">
      <PageTransitionLink to={href} scrollSection={scrollSection}>
        <DropdownItemContent>
          <DropdownItemTitle>{title}</DropdownItemTitle>
          <DropdownItemSubtitle>{subtitle}</DropdownItemSubtitle>
        </DropdownItemContent>
      </PageTransitionLink>
    </DropdownItem>
  );
};

const Dropdown = ({ children, title, dark }) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerTimeoutRef = useRef(null);

  //open dropdown on hover
  const handleMouseEnter = () => {
    clearTimeout(triggerTimeoutRef.current);
    if (!isOpen) {
      toggleDropdown();
    }
  };

  //close dropdown after mouse leaves the menu area
  const handleMouseExit = () => {
    triggerTimeoutRef.current = setTimeout(() => {
      if (isOpen) {
        toggleDropdown();
      }
    }, 200);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownWrapper tabIndex="-1" dark={dark}>
      <Trigger
        aria-haspopup="menu"
        onClick={() => toggleDropdown()}
        onKeyUp={() => toggleDropdown()}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseExit()}
        dark={dark}
      >
        <NavItemTitle>{title}</NavItemTitle>
        <TriggerIcon $rotate={isOpen} aria-hidden={true}>
          <IconCarat />
        </TriggerIcon>
      </Trigger>
      <DropdownContent
        open={isOpen}
        role="menu"
        aria-labelledby={title}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseExit()}
      >
        {children.slice(0, 1)}
        <DropdownLinks>{children.slice(1)}</DropdownLinks>
      </DropdownContent>
    </DropdownWrapper>
  );
};

export const DropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Trigger = styled.button`
  display: flex;
  justify-items: space-between;
  align-items: center;
  gap: 0.375rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  outline: none;
  border: none;
  background none;
  color: ${({ dark }) => (dark ? colors.humeBlack700 : colors.light)};
`;

export const TriggerIcon = styled.span`
  display: inline-block;
  transform: rotate(${(props) => (props.$rotate ? 0 : 180)}deg);
  transition: transform 0.3s ease;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  position: absolute;
  padding: 2rem;
  top: calc(100% + 1.25rem);
  left: 50%;

  background: ${colors.neutralWhite};
  border: 1px solid ${colors.humeTan500};
  border-radius: 0.625rem;

  opacity: ${(props) => (props.open ? 1 : 0)};
  transform: ${(props) =>
    props.open ? 'translateX(-50%)' : 'translateX(-45%)'};
  transform-origin: top center;
  transition: all 0.3s ease;

  pointer-events: ${(props) => (props.open ? 'all' : 'none')};
`;

export const DropdownLinks = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 2rem;
`;

export const DropdownItem = styled.li`
  color: ${colors.humeBlack700};

  &:focus-within {
    color: ${colors.humeTan700};
  }

  & {
    cursor: pointer;
    transition: color 0.3s;
  }

  &:hover {
    color: ${colors.humeTan700};
  }
`;

export const DropdownItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownItemTitle = styled.div`
  ${TextStyle.TextBody}
  font-weight: 500;
  white-space: nowrap;
`;

export const DropdownItemSubtitle = styled.p`
  ${TextStyle.TextBody}
  color: ${colors.humeTan700};
  margin-top: 0.2rem;
`;

export const DropdownFeatured = styled(DropdownItem)`
  flex: 2;
  display: flex;
  min-width: 19rem;
  align-items: flex-end;
  justify-content: flex-start;
  background: ${colors.humeTan500};
  padding: 2rem;
  border-radius: 0.625rem;
`;

export { Dropdown, DropdownMenuItem };

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DropdownMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
};
