import React from 'react';

const IconCarat = ({
  width = 12,
  height = 14,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
      style={{ display: 'inline-block' }}
    >
      <path
        d="M10.0038 5.99998L5.25195 1.24812L0.500098 5.99998"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCarat;
