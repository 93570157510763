const Routes = {
  home: '/',
  about: '/about',
  science: '/science',
  solutions: '/products',
  ethics: '/ethics',
  hub: '/blog',
  contact: '/contact',
  privacyPolicy: '/privacy-policy',
  subscribe: {
    endpoint:
      'https://hume.us13.list-manage.com/subscribe/post?u=49613932ce00630af8e52d83f&amp;id=71a24dc079&amp;f_id=0044e2e2f0',
    tags: '6667843',
  },
};

export default Routes;
