import React from 'react';

const Hamburger = ({
  width = 30,
  height = 8,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 8"
      aria-labelledby="hamburgerTitle"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title id="hamburgerTitle">Toggle menu</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h30v1H0V0zM0 7h30v1H0V7z"
        fill={fill}
      />
    </svg>
  );
};

export default Hamburger;
