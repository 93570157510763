import { useEffect } from 'react';
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from 'locomotive-scroll';
import { animation } from '../../styles/vars/animation.style';
import { breakpoints } from '../../styles/vars/breakpoints.style';

const Scroll = (callbacks) => {
  useEffect(() => {
    const locomotiveScroll = new LocomotiveScroll({
      el: document.getElementById('scroll-container'),
      smooth: true,
      tablet: {
        smooth: true,
        breakpoint: breakpoints.tabletL,
      },
      smartphone: {
        smooth: false,
        breakpoint: breakpoints.mobileL,
      },
      direction: 'vertical',
      getDirection: true,
      touchMultiplier: 1,
      multiplier: 1,
      firefoxMultiplier: 1,
      lerp: 0.2,
      repeat: false,
      class: 'is-inview',
      reloadOnContextChange: true,
    });

    window.scroll = locomotiveScroll;
    window.scroll.stop();

    setTimeout(() => {
      window.scroll.update();
      window.scroll.start();

      window.scroll.on('scroll', (func) => {
        document.documentElement.setAttribute('data-direction', func.direction);
      });
    }, (animation.pageExitDuration + animation.pageEntryDuration) * 1000);

    return () => {
      if (window.scroll) {
        window.scroll.destroy();
      }
    };
  }, [callbacks.callbacks.pathname]);

  return null;
};

export default Scroll;
