import React from 'react';

const HamburgerClose = ({
  width = 23,
  height = 23,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      aria-labelledby="hamburgerCloseTitle"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title id="hamburgerCloseTitle">Close menu</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.916 21.423L21.422.917l.683.684L1.6 22.107l-.683-.684z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.576.917l20.506 20.506-.684.683L.892 1.6l.684-.683z"
        fill={fill}
      />
    </svg>
  );
};

export default HamburgerClose;
