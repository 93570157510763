import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { zIndex } from '../../styles/vars/layout.style';
import { mq } from '../../styles/vars/media-queries.style';

export const CookieBarMain = styled.div`
  position: fixed;
  ${clamp('bottom', 16, 40)}
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 4.4rem);
  max-width: 55.2rem;
  ${clamp('padding-top', 10, 16)}
  ${clamp('padding-bottom', 12, 18)}
  ${clamp('padding-left', 16, 32)}
  ${clamp('padding-right', 16, 32)}
  z-index: ${zIndex.cookieBar};
  text-align: center;
  background-color: #ffffff;
  border: 0.1rem solid ${colors.humeBlack700};
  border-radius: 3rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? undefined : 'none')};
  transition: opacity 0.2s ease;

  ${mq.tabletP} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${mq.deskL} {
    max-width: 31vw;
    padding-top: 1.5rem;
    padding-bottom: 1.8rem;
  }

  a {
    text-decoration: underline;
  }
`;

export const CookieBarBtn = styled.button`
  cursor: pointer;
  padding: 0;
  ${clamp('margin-left', 6, 9)}
  background-color: transparent;
  border: none;
`;
