import styled from 'styled-components';
import { easings } from 'styles/vars/easings.style';
import { mq } from 'styles/vars/media-queries.style';

export const SlideMask = styled.span`
  position: relative;
  display: block;
  overflow: hidden;
`;

export const SlideMaskChild = styled.span`
  display: block;
  will-change: transform;
  transition: transform 0.5s ${easings.buttonHover.hover};

  :nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(110%);
  }

  ${mq.desk} {
    a:hover &,
    button:hover & {
      &:nth-child(1) {
        transform: translateY(-100%);
      }

      &:nth-child(2) {
        transform: translateY(0);
      }
    }
  }
`;
