import React from 'react';

const Logo = ({
  width = 106,
  height = 24,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 106 24"
      aria-labelledby="logoTitle"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title fill={fill} id="logoTitle">
        Hume
      </title>
      <g clipPath="url(#clip0_3775_6360)">
        <path
          d="M1.58628 12.5801C0.353552 12.8628 -0.413558 14.0273 -0.11375 15.2754C0.169308 16.5235 1.36854 17.2563 2.58452 16.9735C3.81724 16.6908 4.5676 15.5263 4.28455 14.2782C4.00149 13.0285 2.80226 12.2973 1.58628 12.5801Z"
          fill="#FFB5D6"
        />
        <path
          d="M2.65085 7.87087C3.63401 8.65386 5.0158 8.52001 5.81641 7.5212C6.61533 6.52239 6.43278 5.14213 5.46636 4.35914C4.49994 3.57616 3.09971 3.70999 2.30079 4.7088C1.50186 5.70761 1.68443 7.08788 2.65085 7.87087Z"
          fill="#D2A7E9"
        />
        <path
          d="M8.61584 19.7028C7.46686 19.154 6.15039 19.6192 5.60102 20.7518C5.05165 21.8828 5.50053 23.1978 6.65118 23.7633C7.80017 24.3121 9.11664 23.8469 9.66601 22.7143C10.1986 21.5666 9.7665 20.2516 8.61584 19.7028Z"
          fill="#FFDCDC"
        />
        <path
          d="M15.5459 19.702C14.3969 20.2508 13.9464 21.5825 14.4957 22.7135C15.0451 23.8445 16.3448 24.328 17.5106 23.7625C18.6595 23.2137 19.1101 21.882 18.5607 20.751C18.0114 19.62 16.6966 19.1365 15.5459 19.702Z"
          fill="#FFD1A4"
        />
        <path
          d="M22.5782 12.58C21.3455 12.2973 20.163 13.0301 19.88 14.2781C19.5969 15.5262 20.3473 16.7074 21.58 16.9734C22.8127 17.2562 23.9952 16.5234 24.2783 15.2753C24.5613 14.0272 23.811 12.8627 22.5782 12.58Z"
          fill="url(#paint0_linear_3775_6360)"
        />
        <path
          d="M21.828 7.87212C22.8112 7.08914 22.977 5.70887 22.1781 4.71006C21.3792 3.71125 19.9957 3.57908 19.0125 4.3604C18.0294 5.14338 17.8635 6.52364 18.6625 7.52245C19.4631 8.52126 20.8449 8.65344 21.828 7.87212Z"
          fill="#A0B0F6"
        />
        <path
          d="M12.0811 0C10.7814 0 9.81494 0.998804 9.81494 2.26363C9.81494 3.52845 10.7814 4.52727 12.0811 4.52727C13.3641 4.52727 14.3472 3.52845 14.3472 2.26363C14.3456 0.997131 13.3641 0 12.0811 0Z"
          fill="#BBABED"
        />
        <path
          d="M41.755 7.2465C39.4051 7.2465 37.6263 8.1031 36.7788 9.55698V2.59375H33.7925V20.7714H36.7788V14.1144C36.7788 12.8378 37.154 11.8039 37.9044 10.9958C38.6547 10.1877 39.601 9.78452 40.7919 9.78452C43.1904 9.78452 44.2355 11.2869 44.2355 14.1144V20.7714H47.2219V14.1144C47.2219 11.8842 46.7981 10.1727 45.9322 9.00822C45.0679 7.84378 43.6811 7.2465 41.755 7.2465Z"
          fill={fill}
        />
        <path
          d="M60.2947 14.1004C60.2947 15.3937 59.9681 16.4427 59.3148 17.2357C58.6616 18.0271 57.7321 18.4152 56.5412 18.4152C54.3052 18.4152 53.3756 16.9279 53.3756 14.1004V7.44336H50.3726V14.1172C50.3726 16.2988 50.7159 17.915 51.5148 19.1263C52.3138 20.3543 53.6687 20.9683 55.5446 20.9683C57.8125 20.985 59.3953 20.2087 60.293 18.6745L60.4236 20.7742H63.2793V7.44503H60.293V14.1004H60.2947Z"
          fill={fill}
        />
        <path
          d="M84.1847 7.23047C81.5736 7.23047 79.7295 8.24768 78.8652 10.1064C78.1467 8.18411 76.646 7.23047 74.3782 7.23047C71.9462 7.23047 70.4455 7.94151 69.6298 9.54095L69.4992 7.44127H66.6753V20.7704H69.6616V14.1134C69.6616 12.8051 69.9883 11.7544 70.6398 10.963C71.2763 10.1717 72.1907 9.78354 73.3481 9.78354C75.5338 9.78354 76.4483 11.2859 76.4483 14.1134V20.7704H79.4515V14.1134C79.4515 12.8051 79.7613 11.7544 80.4145 10.963C81.051 10.1717 81.9638 9.78354 83.1228 9.78354C85.3103 9.78354 86.2231 11.2859 86.2231 14.1134V20.7704H89.2094V14.1134C89.2094 11.9167 88.8828 10.3156 88.1325 9.08922C87.3654 7.87794 86.0288 7.2472 84.1847 7.23047Z"
          fill={fill}
        />
        <path
          d="M99.0638 7.24609C97.1059 7.24609 95.3104 7.92536 93.9889 9.10486C92.6674 10.2844 91.835 12.0779 91.835 14.114C91.835 16.1501 92.6674 17.9436 93.9889 19.1063C95.3104 20.2858 97.1226 20.9651 99.0638 20.9651C100.451 20.9651 101.724 20.6255 102.883 19.9629C104.042 19.3004 104.923 18.3953 105.511 17.2493L102.948 16.038C102.229 17.6374 100.811 18.591 99.0488 18.591C98.0371 18.591 97.1394 18.2681 96.3723 17.6223C95.6052 16.9765 95.1161 16.135 94.9201 15.1011H106.164C106.294 12.7588 105.576 10.7227 104.303 9.3809C103.028 8.05418 101.152 7.24609 99.0638 7.24609ZM94.9185 13.1118C95.1144 12.0611 95.5867 11.2213 96.3388 10.5755C97.0891 9.94472 97.9869 9.62182 99.0638 9.62182C100.141 9.62182 101.055 9.94472 101.806 10.5755C102.556 11.2062 103.03 12.0611 103.226 13.1118H94.9185Z"
          fill={fill}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3775_6360"
          x1="21.4334"
          y1="16.9446"
          x2="22.6817"
          y2="12.7375"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2656" stopColor="#FFB7B2" />
          <stop offset="0.5781" stopColor="#AB9EFC" />
        </linearGradient>
        <clipPath id="clip0_3775_6360">
          <rect width="106" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
