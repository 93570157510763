import { clamp, pxToEm } from '../../styles/utils/conversion.style';
import styled, { css, keyframes } from 'styled-components';

import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { font } from '../../styles/vars/font.style';
import { mq } from '../../styles/vars/media-queries.style';

export const buttonVariants = {
  fill: 'fill',
  outline: 'outline',
  plain: 'plain',
  text: 'text',
};

export const buttonThemes = {
  light: 'light',
  dark: 'dark',
};

const ButtonFill = css`
  color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.light : colors.humeBlack700};
  border-color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.humeBlack700 : colors.light};
  background-color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.humeBlack700 : colors.light};

  /* :not(:disabled):hover {
    background-color: ${colors.light};
  } */
`;

const ButtonOutline = css`
  color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.humeBlack700 : colors.light};
  border-color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.humeBlack700 : colors.light};
`;

const ButtonPlain = css`
  min-width: auto;
  min-height: auto;
  padding: 0;
  color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.humeBlack700 : colors.light};
  border: none;
`;

const ButtonTextOnly = css`
  min-width: auto;
  min-height: auto;
  padding: 0;
  color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.humeBlack700 : colors.light};
  border: none;
  border-bottom: 0.1rem solid ${colors.humeBlack700};
  border-color: ${({ theme }) =>
    theme === buttonThemes.dark ? colors.humeBlack700 : colors.light};
  line-height: 0.85;
  vertical-align: bottom;
`;

export const ButtonIconItem = styled.span`
  display: inline-block;
  will-change: transform;

  :nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: ${({ left, arrowOut }) =>
      left === true
        ? `translateX(110%);`
        : !arrowOut
        ? `translateX(-110%);`
        : `translateX(-110%) translateY(110%)`};
  }
`;

export const ButtonWrapper = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.fullWidth ? '100%' : undefined)};
  min-width: min-content;
  min-height: 4.4rem;
  cursor: pointer;
  font-family: ${(props) =>
    props.variant === buttonVariants.text || props.lower
      ? font.primary
      : font.secondary};
  font-size: ${(props) =>
    props.variant === buttonVariants.text
      ? 'inherit'
      : clamp('font-size', 14, 16)};
  text-align: center;
  background: none;
  margin-bottom: 0.2rem;
  border-width: 0.1rem;
  border-style: solid;
  border-radius: ${(props) =>
    props.variant === buttonVariants.text ? 0 : pxToEm(32, 16)};
  opacity: ${(props) => (props.inactive ? 0.4 : 1)};
  transition: all 0.125s ${easings.inOut.cubic};
  will-change: transform;
  transition: transform 0.5s ${easings.buttonHover.hover},
    opacity 0.5s ${easings.buttonHover.hover};

  ${(props) => {
    const { variant } = props;
    if (variant === buttonVariants.fill) {
      return ButtonFill;
    }

    if (variant === buttonVariants.outline) {
      return ButtonOutline;
    }

    if (variant === buttonVariants.plain) {
      return ButtonPlain;
    }

    if (variant === buttonVariants.text) {
      return ButtonTextOnly;
    }
  }}

  ${mq.desk} {
    &:not(:disabled):hover {
      ${ButtonIconItem} {
        transition: transform 0.4s ${easings.buttonHover.hover};

        &:nth-child(1) {
          transform: ${({ left, arrowOut }) =>
            left === true
              ? `translateX(-100%);`
              : !arrowOut
              ? `translateX(100%);`
              : `translateX(100%) translateY(-100%);`};
        }

        &:nth-child(2) {
          transform: translateX(0);
          transition-delay: 0.1s;
        }
      }
    }
  }
`;

export const ButtonMain = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.var === buttonVariants.plain ||
    props.var === buttonVariants.text ||
    props.icon === 'false'
      ? `padding: 0;`
      : `padding: .9em ${pxToEm(34, 16)} 1.05em;`}
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  transition: opacity 0.15s ${easings.inOut.cubic};
`;

export const ButtonText = styled.span`
  font-weight: ${(props) =>
    props.lower ? font.weight.regular : font.weight.medium};
  line-height: 1.1;
  letter-spacing: 0.01em;
  text-align: left;
  white-space: nowrap;
  text-transform: ${(props) => (props.lower ? 'none' : 'uppercase')};
`;

export const ButtonIcon = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ButtonIconLeft = styled.span`
  ${ButtonIcon}
  margin-right: 0;
  top: -1px;
`;

export const ButtonIconRight = styled.span`
  ${ButtonIcon}
  margin-left: 0;
  top: -1px;
`;

export const ButtonIconOnly = styled.span`
  ${ButtonIcon}
  margin-left: 0;
  margin-right: 0;
`;

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

export const ButtonLoading = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.6em;
  height: 1.6em;
  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  transition: opacity 0.15s ${easings.inOut.cubic};

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: ${(props) =>
      props.isLoading
        ? css`
            ${rotateAnimation} 2s linear infinite
          `
        : undefined};
  }

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${(props) =>
      props.isLoading
        ? css`
            ${dashAnimation} 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite
          `
        : undefined};
  }
`;

export const SlideMask = styled.span`
  position: relative;
  display: block;
  overflow: hidden;
`;
export const SlideMaskFill = styled.span`
  border-radius: ${pxToEm(32, 16)};
  position: relative;
  display: block;
  overflow: hidden;
  will-change: transform;
`;

export const SlideMaskChild = styled.span`
  border-radius: ${pxToEm(32, 16)};
  display: block;
  will-change: transform;
  transition: transform 0.5s ${easings.buttonHover.hover};

  :nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(110%);
  }

  ${mq.desk} {
    a:hover &,
    button:not(:disabled):hover & {
      &:nth-child(1) {
        transform: translateY(-100%);
      }

      &:nth-child(2) {
        transform: translateY(0);
      }
    }
  }
`;

export const SlideMaskChildFill = styled.span`
  display: block;
  padding: ${pxToEm(16, 16)} ${pxToEm(30, 16)} ${pxToEm(18, 16)};
  padding: 0.9em 1.625em 1.05em;
  will-change: transform;
  transition: transform 0.5s ${easings.buttonHover.hover};

  :nth-child(2) {
    background: ${colors.humeBlack700};
    color: ${colors.light};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(110%);
  }

  ${mq.desk} {
    a:not(:disabled):hover &,
    button:hover & {
      &:nth-child(1) {
        transform: translateY(-100%);
      }

      &:nth-child(2) {
        transform: translateY(0);
      }
    }
  }
`;

export const ButtonIconWrapper = styled.span`
  position: relative;
  display: block;
  overflow: hidden;

  ${mq.deskL} {
    font-size: 1.8rem;
  }

  ${({ iconOnly, left, variant }) =>
    variant === buttonVariants.plain || variant === buttonVariants.text
      ? iconOnly === true
        ? ``
        : left === true
        ? `margin-right: .7em; margin-top: -.1em;`
        : `margin-left: .7em;  margin-top: -.1em;`
      : left === true
      ? `margin-right: 1em; margin-top: -.2em;`
      : `margin-left: 1em;  margin-top: -.2em;`}
`;

export const ButtonIconItemNoHover = styled.span`
  display: inline-block;
  will-change: transform;

  ${({ left }) => (left === true ? `margin-right: 1em;` : `margin-left: 1em;`)}
`;
