import styled from 'styled-components';
import { breakpoints } from '../../../styles/vars/breakpoints.style';
import { grid } from '../../../styles/vars/grid.style';
import { mq } from '../../../styles/vars/media-queries.style';

export const GridItemMain = styled.div`
  ${(props) => {
    const columnSpanStyles = Object.keys(breakpoints).reduce(
      (stylesString, breakpointName) => {
        let styles = stylesString;

        if (props[breakpointName]) {
          const colSpan = props[`${breakpointName}Start`]
            ? `${props[`${breakpointName}Start`]} / `
            : '';

          styles = `${styles}
            ${mq[breakpointName]} {
              grid-column: ${colSpan}span ${props[breakpointName]};
            }
          `;
        }

        if (props[`${breakpointName}Order`]) {
          styles = `${styles}
            ${mq[breakpointName]} {
              order: ${props[`${breakpointName}Order`]};
            }
          `;
        }

        return styles;
      },
      ``,
    );

    return `
      grid-column: 1 / span ${grid.colCount};
      ${columnSpanStyles}
    `;
  }}
`;
